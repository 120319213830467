<template src='./template.html'></template>

<script>
export default {
  name: "ProjectView",
  props: {
    customers: {
      require: true,
      type: Array
    },
    project_type: {
      require: true,
      type: Object
    }
  },
  data() {
    return {
      dialog: false,
      valid: false,
      editedItem: {
        CustomerID: "",
        Name: "",
        Budget: "",
        StartDate: "2021-04-23",
        Day: "10",
        Type1: "",
        Type2: "",
        Remark: ""
      },
      defaultItem: {
        CustomerID: "",
        Name: "",
        Budget: "",
        StartDate: "2021-04-23",
        Day: "10",
        Type1: "",
        Type2: "",
        Remark: ""
      },
      status_array: {
        W: "未審核",
        P: "尚未開始",
        D: "進行中",
        E: "結束",
        C: "未通過"
      },
      date_menu: false,
      rules: {
        required: value => { return (value == '') ? '此欄位不得留空' : true },
      },
    }
  },
  methods: {
    Open(item) {
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      this.valid = true
    },
    Close() {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.dialog = false
      this.valid = true
    },
    StatusColorFilter(status) {
      let colors = {
        W: "blue-grey lighten-2 white--text",
        P: "blue-grey darken-4 white--text",
        D: "green darken-3 white--text",
        E: "deep-orange darken-4 white--text",
        C: "amber accent-4 white--text"
      }
      return colors[status]
    },
    GetEndDate(date, day) {
      let now_date = new Date(date);
      return now_date.addDays(day).Format("yyyy-MM-dd");
    },
    GetCustomer(id) {
      let now_customer = this.customers.filter(item => item.CustomerID == id)

      return now_customer.length > 0 ? now_customer[0].Name : ""
    },

    DateSave(date) {
      this.$refs.date_menu.save(date)
    },
    Validate() {
      this.$refs.CustomerForm.validate()
      setTimeout(() => {
        this.valid ? this.CreateCustomer() : ""
      }, 100);
    },
    async CreateCustomer() {
      this.$store.commit("SetLoading", true)
      let response = await this.SendData("/admin/project", "patch", { Data: this.editedItem })
      this.$store.commit("SetLoading", false)
      if (response != "error") {

        this.Close()
        this.$emit("load-data")
        this.$store.commit("SetSnackBar", { status: true, content: "已成功更新廣告案件" })
      }
    }
  },
  computed: {
    service_charge() {
      let now_customer = this.customers.filter(item => item.CustomerID == this.editedItem.CustomerID)
      if (now_customer.length <= 0) {
        return ""
      } else {
        let charge_percent = now_customer[0].ChargeServiceRatio
        charge_percent = parseInt(charge_percent) * 0.01
        return parseInt(this.editedItem.Budget) * charge_percent
      }
    },
  },
  filters: {
    currency(price) {
      let new_price = Math.abs(parseInt(price)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      return (parseInt(price) < 0) ? "- $" + new_price : " $" + new_price
    },
  }
}
</script>