<template src="./template.html"></template>

<script>
export default {
  name: "ProjectDelete",
  props: {
    customers: {
      require: true,
      type: Array
    }
  },
  data() {
    return {
      project: null,
      dialog: false
    }
  },
  methods: {
    Open(item) {
      this.project = Object.assign({}, item)
      this.dialog = true
    },
    Close() {
      this.project = null
      this.dialog = false
    },
    async DeleteData() {
      this.$store.commit("SetLoading", true)
      let response = await this.SendData("/admin/project", "delete", { Data: { ProjectID: parseInt(this.project.ProjectID) } })
      this.$store.commit("SetLoading", false)
      if (response != "error") {
        // this.CreateMoneyRecord()
        this.Close()
        this.$emit("load-data")
        this.$store.commit("SetSnackBar", { status: true, content: "已成功刪除廣告案件" })
      }
    },
    async CreateMoneyRecord() {
      let Data = {
        Type: "I",
        CustomerID: this.project.CustomerID,
        ProjectID: this.project.ProjectID,
        Amount: this.project.Budget,
        AccountDate: new Date().Format("yyyy-MM-dd"),
        Remark: this.project.Name + " - 案件刪除廣告費用抵銷",
        MoneyItemID: 4
      }
      this.$store.commit("SetLoading", true)
      let response = await this.SendData("/admin/money", "post", { Data: Data })
      this.$store.commit("SetLoading", false)
      if (response != "error") {

        this.CreateChargeRecord()
      }
    },
    async CreateChargeRecord() {
      let charge_percent = this.customers.filter(item => item.CustomerID == this.project.CustomerID)[0].ChargeServiceRatio
      charge_percent = parseInt(charge_percent) * 0.01
      let Data = {
        Type: "I",
        CustomerID: this.project.CustomerID,
        ProjectID: this.project.ProjectID,
        Amount: Math.ceil(parseInt(this.project.Budget) * charge_percent),
        AccountDate: new Date().Format("yyyy-MM-dd"),
        Remark: this.project.Name + " - 案件刪除服務費用抵銷",
        MoneyItemID: 4
      }
      this.$store.commit("SetLoading", true)
      let response = await this.SendData("/admin/money", "post", { Data: Data })
      this.$store.commit("SetLoading", false)
      if (response != "error") {

        this.Close()
        this.$emit("load-data")
        this.$store.commit("SetSnackBar", { status: true, content: "已成功刪除廣告案件" })
      }
    }
  }
}
</script>