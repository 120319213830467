<template src='./template.html'></template>

<script>
export default {
  name: "ProjectCreate",
  props: {
    customers: {
      require: true,
      type: Array
    },
    project_type: {
      require: true,
      type: Object
    }
  },
  data() {
    return {
      dialog: false,
      valid: false,
      editedItem: {
        CustomerID: "",
        Name: "",
        Budget: "",
        StartDate: "2021-04-23",
        Day: "5",
        Type1: "",
        Type2: [],
        Remark: ""
      },
      defaultItem: {
        CustomerID: "",
        Name: "",
        Budget: "",
        StartDate: "2021-04-23",
        Day: "5",
        Type1: "",
        Type2: [],
        Remark: ""
      },
      date_menu: false,
      rules: {
        required: value => { return (value == '') ? '此欄位不得留空' : true },
      },
    }
  },
  methods: {
    Open() {
      this.editedItem.StartDate = new Date().Format("yyyy-MM-dd")
      this.dialog = true
      this.valid = true
    },
    Close() {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.dialog = false
      this.valid = true
    },
    DateSave(date) {
      this.$refs.date_menu.save(date)
    },
    Validate() {
      this.$refs.ProjectForm.validate()
      setTimeout(() => {
        this.valid ? this.CreateProject() : ""
      }, 100);
    },
    async CreateProject() {
      let Data = Object.assign({}, this.editedItem)
      let type_string = ""
      Data.Type2.forEach((item, item_index) => {
        if (item_index != Data.Type2.length - 1) {
          type_string += item + ","
        } else {
          type_string += item
        }
      })
      Data.Type2 = type_string
      this.$store.commit("SetLoading", true)
      let response = await this.SendData("/admin/project", "post", { Data: Data })
      this.$store.commit("SetLoading", false)
      if (response != "error") {
        this.CreateMoneyRecord(response)
      }
    },
    async CreateMoneyRecord(project) {
      let Data = {
        Type: "O",
        CustomerID: project.CustomerID,
        ProjectID: project.ProjectID,
        Amount: project.Budget,
        AccountDate: project.StartDate,
        Remark: project.Name + " - 案件廣告費用",
        MoneyItemID: 2
      }
      this.$store.commit("SetLoading", true)
      let response = await this.SendData("/admin/money", "post", { Data: Data })
      this.$store.commit("SetLoading", false)
      if (response != "error") {

        this.CreateChargeRecord(project)
      }
    },
    async CreateChargeRecord(project) {
      let charge_percent = this.customers.filter(item => item.CustomerID == project.CustomerID)[0].ChargeServiceRatio
      charge_percent = parseInt(charge_percent) * 0.01
      let Data = {
        Type: "O",
        CustomerID: project.CustomerID,
        ProjectID: project.ProjectID,
        Amount: Math.ceil(parseInt(project.Budget) * charge_percent),
        AccountDate: project.StartDate,
        Remark: project.Name + " - 案件服務費用",
        MoneyItemID: 1
      }
      this.$store.commit("SetLoading", true)
      let response = await this.SendData("/admin/money", "post", { Data: Data })
      this.$store.commit("SetLoading", false)
      if (response != "error") {

        this.Close()
        this.$emit("load-data")
        this.$store.commit("SetSnackBar", { status: true, content: "已成功新增廣告案件" })
      }
    }
  },
}
</script>