var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pa-md-10 pa-sm-1 pa-3",attrs:{"id":"Projects"}},[_c('CreateProject',{ref:"CreateProject",attrs:{"customers":_vm.customers,"project_type":_vm.project_type},on:{"load-data":_vm.LoadData}}),_c('EditCProject',{ref:"EditCProject",attrs:{"customers":_vm.customers,"project_type":_vm.project_type},on:{"load-data":_vm.LoadData}}),_c('DeleteProject',{ref:"DeleteProject",attrs:{"customers":_vm.customers},on:{"load-data":_vm.LoadData}}),_c('ViewProject',{ref:"ViewProject",attrs:{"customers":_vm.customers,"project_type":_vm.project_type}}),_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"pt-10",attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.status_array,"item-text":"value","item-value":"key","label":"案件狀態"},model:{value:(_vm.active_status),callback:function ($$v) {_vm.active_status=$$v},expression:"active_status"}})],1),_c('v-col',{staticClass:"pt-10",attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.customers,"item-text":"Name","item-value":"CustomerID","label":"案件客戶"},model:{value:(_vm.active_customer),callback:function ($$v) {_vm.active_customer=$$v},expression:"active_customer"}})],1),_c('v-col',{staticClass:"pt-10",attrs:{"cols":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"日期區間","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","scrollable":"","locale":"zh-tw"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" 取消 ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.ChangeDate(_vm.date)}}},[_vm._v(" 確認 ")])],1)],1)],1)],1),_c('v-card',{attrs:{"color":"#fff"}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.filter_projects,"single-expand":false,"expanded":_vm.expanded,"footer-props":_vm.footerprops,"item-key":"ProjectID","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),(_vm.account().role != 'V')?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.OpenCreate}},[_vm._v(" 新增案件 ")]):_vm._e()],1)]},proxy:true},{key:"item.View",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.OpenView(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"item.CustomerID",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.CustomerFilter(item.CustomerID))+" ")]}},{key:"item.ProjectType",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0",class:_vm.ProjectTypeColor(item.Type1)},[_vm._v(_vm._s(_vm.ProjectTypeFilter(item.Type1))+" ")])]}},{key:"item.Name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("word_break")(item.Name))+" ")]}},{key:"item.Budget",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.Budget))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pl-16 py-4",attrs:{"colspan":headers.length}},[_c('p',{staticClass:"ma-0 mb-3"},[_c('span',{staticClass:"text--secondary"},[_vm._v("貼文名稱：")]),_vm._v(_vm._s(item.Name))]),_c('p',{staticClass:"ma-0 mb-3"},[_c('span',{staticClass:"text--secondary"},[_vm._v("投放平台：")]),_vm._v(_vm._s(item.Type1))]),_c('p',{staticClass:"ma-0 mb-3"},[_c('span',{staticClass:"text--secondary"},[_vm._v("投放目的：")]),_vm._v(_vm._s(item.Type2))]),_c('p',{staticClass:"ma-0 mb-3"},[_c('span',{staticClass:"text--secondary"},[_vm._v("投放預算：")]),_vm._v(_vm._s(item.Budget)+"元")]),_c('p',{staticClass:"ma-0"},[_c('span',{staticClass:"text--secondary"},[_vm._v("投放日期：")]),_vm._v(_vm._s(item.Day)+"天，"+_vm._s(item.StartDate)+" ~ "+_vm._s(item.EndDate))])])]}},{key:"item.Status",fn:function(ref){
var item = ref.item;
return [(_vm.ProjectStatusEnable(item))?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:_vm.StatusColorFilter(item.Status),attrs:{"dark":"","text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.StatusFilter(item.Status))+" ")])]}}],null,true)},[_c('v-list',[_vm._l((_vm.StatusListFilter()),function(status,index){return [_c('v-list-item',{key:index},[_c('v-list-item-title',[_c('v-btn',{attrs:{"text":"","color":"primary","dark":""},on:{"click":function($event){return _vm.UpdateStatus(status.key,item)}}},[_vm._v(" "+_vm._s(status.value)+" ")])],1)],1)]})],2)],1):_c('p',{staticClass:"ma-0 ",class:_vm.StatusColorFilter(item.Status)},[_vm._v(" "+_vm._s(_vm.StatusFilter(item.Status)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.account().role != 'V')?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.OpenEdit(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.account().role == 'A'||_vm.account().role == 'C')?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.OpenDelete(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('p',[_vm._v("目前沒有資料")])]},proxy:true}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }