<template src='./template.html'></template>

<script>
export default {
  name: "ProjectEdit",
  props: {
    customers: {
      require: true,
      type: Array
    },
    project_type: {
      require: true,
      type: Object
    }
  },
  data() {
    return {
      dialog: false,
      valid: false,
      editedItem: {
        CustomerID: "",
        Name: "",
        Budget: "",
        StartDate: "2021-04-23",
        Day: "10",
        Type1: "",
        Type2: [],
        Remark: ""
      },
      defaultItem: {
        CustomerID: "",
        Name: "",
        Budget: "",
        StartDate: "2021-04-23",
        Day: "10",
        Type1: "",
        Type2: [],
        Remark: ""
      },
      date_menu: false,
      rules: {
        required: value => { return (value == '') ? '此欄位不得留空' : true },
      },
    }
  },
  methods: {
    Open(item) {
      this.editedItem = Object.assign({}, item)
      this.editedItem.Type2 = this.editedItem.Type2.split(",")
      this.dialog = true
      this.valid = true
    },
    Close() {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.dialog = false
      this.valid = true
    },
    DateSave(date) {
      this.$refs.date_menu.save(date)
    },
    Validate() {
      this.$refs.CustomerForm.validate()
      setTimeout(() => {
        this.valid ? this.CreateCustomer() : ""
      }, 100);
    },
    async CreateCustomer() {
      let Data = Object.assign({}, this.editedItem)
      let type_string = ""
      Data.Type2.forEach((item, item_index) => {
        if (item_index != Data.Type2.length - 1) {
          type_string += item + ","
        } else {
          type_string += item
        }
      })
      Data.Type2 = type_string
      this.$store.commit("SetLoading", true)
      let response = await this.SendData("/admin/project", "patch", { Data: Data })
      this.$store.commit("SetLoading", false)
      if (response != "error") {

        this.Close()
        this.$emit("load-data")
        this.$store.commit("SetSnackBar", { status: true, content: "已成功更新廣告案件" })
      }
    }
  },
}
</script>